$(function () {
  checkWindowScroll();
  offsetContent();
  sliders();

  $(window).on("scroll", () => {
    checkWindowScroll();
  });

  // Burger click
  $("#burger").click(function () {
    open = !open;
    toggleMenu(open);
  });

  // Submenu.
  $(".submenu-toggle").on("click", function () {
    var toggle = $(this);
    toggle.toggleClass("open");
    $(this).prev(".parent-item").toggleClass("open");
    var submenu = $(this).next(".submenu");
    submenu.toggleClass("is-open");
    submenu.children("ul").slideToggle("fast");
  });

  // Active class.
  $("header .nav [href]").each(function () {
    if (this.href == window.location.href) {
      $(this).addClass("active");
    }
  });
});

function checkWindowScroll() {
  let scroll = $(window).scrollTop();

  if (scroll > 0) {
    $("header.new-header").addClass("scroll");
  } else {
    $("header.new-header").removeClass("scroll");
  }
}

function offsetContent() {
  let header = $("header.new-header");
  let next = $(
    "header.new-header + section, header.new-header + div, .offset-section"
  );

  if (next.length) {
    next.css("margin-top", `${header.outerHeight()}px`);
  }
}

/**
 * @description handles opening and closing of the mobile menu on the site
 *
 * @param {*} open flag indicating whether or not the menu is in an open state
 */
var open = false;
function toggleMenu(open) {
  $("#burger").toggleClass("open", open);
  $("#navigation-mobile ul").toggleClass("open", open);
}

function sliders() {
  // $(".test-wrap").slick({
  //   dots: true,
  //   infinite: true,
  //   speed: 300,
  //   slidesToShow: 4,
  //   adaptiveHeight: true,
  //   autoplay: true,
  //   autoplaySpeed: 8000,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: true,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // });

  $(".test-wrap").slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 8000,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    slidesToScroll: 1,
    slidesToShow: 1,
  });
}
